import type { IBox } from './box.interface'
import type { IUser } from './user.interface'

export enum PaymentMethod {
	CARD = 'card',
	PIX = 'pix',
}

export enum OrderStatus {
	PENDING = 'pending',
	PAID = 'paid',
	CANCELED = 'canceled',
	FAILED = 'failed',

	ASSEMBLING_BOX = 'assembling_box',
	SHIPPING_BOX = 'shipping_box',
	SHIPPED = 'shipped',
}

export enum OrderType {
	BOX = 'box',
}

export enum Currency {
	REAL = 'brl',
	DOLLAR = 'usd',
}

export interface IOrder extends Document {
	_id: string
	subtotal: number
	streamer: string | IUser
	total: number
	currency: 'brl' | 'usd'
	paymentMethod: 'card' | 'pix'
	gatewayId: string
	entity: string | IBox
	orderType: OrderType
	address: IUser['address']
	status: OrderStatus
	customer: {
		name: string
		email: string
		phone: string
	}
	delivery: {
		courier: string
		trackingCode: string
		price: number
		deliveredAt: Date
	}
	createdAt: Date
	commissionPercentage: number
}
